<template>
  <div class="clebex-widget-item content">
    <div
      v-for="preset in items"
      :key="preset.id"
      class="clebex-widget-plan-wrapper"
      @click="
        $router.push({
          name: 'r_bookings'
        })
      "
    >
      <div class="clebex-widget-plan-text">
        {{ preset.itemable.preset_name }}
      </div>
      <section class="widget-summary">
        <section
          v-for="(approvalStatus, index) in preset.itemable.approval_statuses"
          :key="index"
        >
          <Popper hover openDelay="500" :content="approvalStatus.name">
            <div class="widget-summary-item">
              <icon
                :class="{
                  success: approvalStatus.status === 'APPROVED',
                  warning: approvalStatus.status === 'PENDING',
                  error: approvalStatus.status === 'DECLINED'
                }"
                icon="#cx-vis1-expected"
                width="55"
                height="28"
              />
              <div class="widget-count">
                {{ approvalStatus.count }}
              </div>
            </div>
          </Popper>
        </section>
        <Popper
          hover
          openDelay="500"
          :content="displayLabelName('bookings.bookings.bookings')"
        >
          <div class="widget-summary-item">
            <icon icon="#cx-men1-booking" width="55" height="28" />
            <div class="widget-count">
              {{ preset.itemable.declarations }}
            </div>
          </div>
        </Popper>
        <Popper
          hover
          openDelay="500"
          :content="displayLabelName('bookings.bookings.hosts')"
        >
          <div class="widget-summary-item">
            <icon icon="#cx-vis1-host" width="55" height="28" />
            <div class="widget-count">
              {{ preset.itemable.hosts }}
            </div>
          </div>
        </Popper>
        <Popper
          hover
          openDelay="500"
          :content="displayLabelName('bookings.bookings.resources')"
        >
          <div class="widget-summary-item">
            <icon icon="#cx-men1-resources" width="55" height="28" />
            <div class="widget-count">
              {{ preset.itemable.resources }}
            </div>
          </div>
        </Popper>
      </section>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Popper from "vue3-popper";

export default {
  name: "WidgetsBookingsContent",
  components: {
    Popper
  },
  methods: {
    ...mapActions("cockpit", ["getWidgetItem"])
  },
  props: {
    items: {
      type: Array,
      required: true
    }
  }
};
</script>
